const coreApi = process.env.REACT_APP_CORE_API + "api/users/v1/";
const coreSSOApi = process.env.REACT_APP_CORE_API + "api/sso/v1/";
const analyticApi = process.env.REACT_APP_SEARCH_API + "api/analytic/";
const postApi = process.env.REACT_APP_CORE_API + "api/control-panel/v1/";
const pollsApi = process.env.REACT_APP_ENGAGEMENT_API + "api/engagement/v1/";
const eventApi = process.env.REACT_APP_ENGAGEMENT_API + "api/events/v1/";
const adsApi = process.env.REACT_APP_CORE_API + "api/ads/v1/";
const feedCustomizeApi = process.env.REACT_APP_CORE_API + "api/customize-feed/";
// const feedCustomizeApi = "http://localhost:3072/api/customize-feed/";
const videosApi = process.env.REACT_APP_CORE_API + "api/videos/v1/";
const utmDecodeApi = process.env.REACT_APP_CORE_API + "api/metadata/v1/device/decode-utm";
const reportsV1Api = process.env.REACT_APP_MOM_API + "api/reports/v1/";
const notificationApi = process.env.REACT_APP_NOTIFICATION_API + "api/v1/";
// Const adsApi = "http://localhost:3072/api/ads/v1/";
const groupApi = process.env.REACT_APP_CORE_API + "api/groups/v1/";
// Const groupApi = 'http://localhost:3072/api/groups/v1/';
const productApi = process.env.REACT_APP_CORE_API + "api/products/v1/";
const feedApi = process.env.REACT_APP_CORE_API + "api/content/v1/";
const statsApi = process.env.REACT_APP_NOTIFICATION_API;
// Const allDataApi = process.env.REACT_APP_SEARCH_/API + "api/analytic/";
export const attributionApi = process.env.REACT_APP_ATTRIBUTION_API;
const buzzFeedApi = process.env.REACT_APP_CORE_API + "api/buzz-feed/";
const connectionApi = process.env.REACT_APP_CORE_API + "api/connections/v1/";
const connectionFollowApi = process.env.REACT_APP_CORE_API + "api/followers/v1/";
const activityApi = process.env.REACT_APP_ACTIVITY_API + "api/v1/";
const internalUserApi = process.env.REACT_APP_CORE_API + "api/internalUsers/v1/";
const brandlabsApi = process.env.REACT_APP_BRANDLABS_API;
const apiUrl = {
  loginUrl: coreApi + "login",
  requestPhoneOtp: coreApi + "request-phone-otp",
  verifyPhoneOtp: coreApi + "verify-phone-otp",
  loginSSOUrl: coreSSOApi + "login",
  registerUrl: coreApi + "add-user",
  verifyEmailUrl: coreApi + "verify-email",
  profileUrl: coreApi,
  otherUsersProfile: coreApi + "profile",
  deleteUserUrl: coreApi + "delete-user-byadmin",
  updateUserRole: coreApi + "update-role",
  updatePassword: coreApi + "change-password",
  searchUrl: coreApi + "users",
  impersonateLogin: coreApi + "impersonate-login",
  // impersonateLogin: "http://localhost:3072/api/users/v1/impersonate-login",
  // switchBackLogin: "http://localhost:3072/api/users/v1/switch-back-login",
  switchBackLogin: coreApi + "switch-back-login",
  // searchUrl: "http://localhost:3072/api/users/v1/users",
  //getUser: "http://localhost:3072/api/users/v1/user",
  getUser: coreApi + "user",
  postUrl: postApi + "feed-posts",
  createPost: process.env.REACT_APP_CORE_API + "api/content/v1/post/",
  createComment: process.env.REACT_APP_CORE_API + "api/content/v1/post/comment/",
  postLikeUrl: process.env.REACT_APP_CORE_API + "api/content/v1/posts/",
  answerByPostId: process.env.REACT_APP_CORE_API + "api/content/v1/post/answers",
  postAnswer: process.env.REACT_APP_CORE_API + "api/content/v1/post/answer",
  commentsByPostId: process.env.REACT_APP_CORE_API + "api/content/v1/post/comments",
  likeAnswer: process.env.REACT_APP_CORE_API + "api/content/v1/post/answer-like-unlike",
  deleteAnswer: process.env.REACT_APP_CORE_API + "api/content/v1/post/answer",
  qaBgImages: process.env.REACT_APP_CORE_API + "api/content/v1/bg-images",
  post: postApi + "feed-posts",
  postByUserIdUrl: postApi + "user-posts",
  postByGroupIdUrl: postApi + "group-posts",
  postByPostIdUrl: postApi + "post",
  commentUrl: postApi + "post-comments",
  deleteComment: postApi + "delete-comment",
  groupUrl: groupApi + "admin/groups",
  groupMembersUrl: groupApi + "group-members",
  updateGroupUrl: groupApi + "group",
  groupTagsUrl: groupApi + "tags",
  groupByIdUrl: groupApi + "admin/group",
  groupJoinedUsers: groupApi + "group-members-details",
  analyticUrl: analyticApi + "data",
  productsUrl: productApi + "categories",
  productUrl: String(productApi),
  feedUrl: String(feedApi),
  feedWeeksUrl: feedApi + "feed-weeks",
  deletePostUrl: feedApi + "post",
  analyticCountUrl: analyticApi + "count",
  emailUrl: process.env.REACT_APP_EMAIL_API + "bulk-mail",
  notificationUrl: process.env.REACT_APP_CORE_API + "api/notifications/v1/send-notificationv2",
  sendNotificationUrl: process.env.REACT_APP_NOTIFICATIONS_API + "api/v1/fcm/add-notification",
  emailStatsUrl: statsApi + "getMailerDataByCategory",
  emailStatsByWeekUrl: statsApi + "dataPerWeek",
  userStatsUrl: statsApi + "getUserStats",
  groupCreateUrl: process.env.REACT_APP_CORE_API + "api/groups/v1/group/",
  adsUrl: adsApi + "ad",
  getAdsUrl: adsApi + "ads",
  getTopArticlesUrl: statsApi + "getArticleDetails",
  articlesUrl: feedApi + "articles",
  CategoriesUrl: feedApi + "categories",
  getavgSessionDurationUrl: statsApi + "getavgSessionDuration",
  sponsoredPost: process.env.REACT_APP_BRANDLABS_API + "admin/api/sponsored-posts",
  sponsoredPostById: process.env.REACT_APP_BRANDLABS_API + "admin/api/sponsored-post",
  ticketById: process.env.REACT_APP_BRANDLABS_API + "admin/api/ticket/",
  fileDetailsById: process.env.REACT_APP_BRANDLABS_API + "admin/api/file-details/",
  putSponsoredPostUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/sponsored-post",
  ticketUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/ticket",
  sendMessageUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/send-message",
  transactionByIdUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/transaction?productId=",
  applyCouponUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/validate-coupon",
  paymentUpdateUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/payment-status",
  emaiAddsPostUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/email-adds",
  emaiAdsByIdUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/email-add",
  couponUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/coupons/",
  createCouponUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/create-coupon/",
  deleteCouponUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/coupon/",
  getLeadsUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/leadgen/get-all",
  mediaUrl: process.env.REACT_APP_MEDIA_API + "api/media/",
  campaignUrl: attributionApi + "v1/campaigns/",
  expertsApi: process.env.REACT_APP_CORE_API + "api/experts/v1/",
  notificationStatsUrl: statsApi + "notification/getSendLabel",
  allCityUrl: analyticApi + "countbycity",
  allPincodeUrl: analyticApi + "countbypincode",
  connectionCountUrl: analyticApi + "getConnectionCount",
  notificationLabelUrl: statsApi + "notification/getNotificationLabels",
  notificationLabelOfUrl: statsApi + "notification/getNotificationsOfLabels",
  utmUrl: reportsV1Api + "utm",
  cleverTapUrl: reportsV1Api + "clevertap",
  sessionUrl: reportsV1Api + "session",
  createInternalUserUrl: internalUserApi + "internal-users",
  createdByUserDetail: internalUserApi + "created-by",
  impersonateUserUrl: internalUserApi + "impersonate",
  updateInternalUser: internalUserApi + "update-internal-user",
  notificationLabelStatsUrl: statsApi + "notification/getUserDataAccordingToDay",
  utmDecodeUrl: utmDecodeApi,
  quizUrl: buzzFeedApi + "quiz",
  quizUsersUrl: buzzFeedApi + "users",
  getAllQuizUrl: buzzFeedApi + "quizs",
  quizResultUrl: buzzFeedApi + "result",
  quizQuesUrl: buzzFeedApi + "question",
  quizOptionUrl: buzzFeedApi + "option",
  createVideoUrl: videosApi + "video",
  getAllVideoUrl: videosApi + "videos",
  getConnectionDataUrl: connectionApi + "connection-history",
  getNumberOfFollowUrl: connectionFollowApi + "no-of-follow",
  getNumberOfUserSendingFollowUrl: connectionFollowApi + "no-of-users-sending-follow",
  getNumberOfFollowUsersUrl: connectionFollowApi + "no-of-users",
  getActivityLogsUrl: activityApi + "activity-logs",
  getAllBrandsUrl: brandlabsApi + "admin/api/onboarding/",
  getSingleBrandUrl: brandlabsApi + "api/onboarding/",
  getScrollUrl: attributionApi + "api/reports/",
  getCampaignUrl: brandlabsApi + "admin/api/campaign/",
  getBrandUrl: brandlabsApi + "api/onboarding/",
  getSearchHistory: process.env.REACT_APP_CORE_API + "api/tools/v1/search-histroy",
  sendMessage: process.env.REACT_APP_CHATS_API + "api/admin/send-message",
  deleteInternalUserUrl: internalUserApi + "delete-internal-user",
  getMetaAPI: process.env.REACT_APP_CORE_API + "api/metadata/v1/registration-data",
  groupStatsUrl: postApi + "reports/groups-data",
  silentNotificationStatsUrl: statsApi + "getSilentNotificationReport",
  feedCustomizeUrl: feedCustomizeApi,
  appSetting: process.env.REACT_APP_CORE_API + "api/app-config/app-setting",
  appVersion: process.env.REACT_APP_CORE_API + "api/app-config/app-versions",
  menus: process.env.REACT_APP_CORE_API + "api/flag-report/v1/flag-report",
  forget: process.env.REACT_APP_CORE_API + "api/users/v1/forgot-password",
  verifyOtp: process.env.REACT_APP_CORE_API + "api/users/v1/verify-otp",
  resetPassword: process.env.REACT_APP_CORE_API + "api/users/v1/reset-password",
  displayInFeed: process.env.REACT_APP_CORE_API + "api/control-panel/v1/update-posts",
  allPolls: pollsApi + "polls",
  pollByEngagement: pollsApi + "poll",
  optedUsers: pollsApi + "opted-users-list",

  getAllEvents: eventApi + "events-list",
  createEvent: eventApi + "event",
  eventDetails: eventApi + "single-event",
  participantsList: eventApi + "participants",
  updateEventUserStatus: eventApi + "update-status",
  deleteEvent: eventApi + "delete",
  // Chat
  //chat: "http://localhost:3003/api/ai/v1/chat"
  chat: process.env.REACT_APP_CHATS_API + "api/ai/v1/chat",
  newConversation: process.env.REACT_APP_CHATS_API + "api/ai/v1/new-conversation",
  message: process.env.REACT_APP_CHATS_API + "api/ai/v1/message",
  emailContent: notificationApi + "email"
};

export default apiUrl;
